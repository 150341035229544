.footer{
    background-color: var(--top-color);
    padding: 50px 0;
}
.footer-col{
    text-align: left;
}
.footer-col h3{
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    margin: 0 0 25px;
    text-transform: capitalize;
}
.footer-col p{
    font-size: 16px;
    color: rgba(255,255,255,0.9);
    font-weight: 300;
    line-height: 26px;
    margin: 0;
}
.footer-link{
    margin: 0;
    display: block;
}
.footer-link .link-item{
    display: block;
}
.footer-link .link-item:not(:last-child){
    margin-bottom: 10px;
}
.footer-link a{
    font-size: 16px;
    color: rgba(255,255,255,0.9);
    display: inline-block;
    font-weight: 300;
    text-transform: capitalize;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    text-decoration: none;
}
.footer-link a:hover{
    padding-left: 5px;
    text-decoration: underline;
}
 .copyright-text{
    padding:  2px;
    text-align: center;
    color: rgba(255,255,255,0.9);
    font-size: 12px;
    font-weight: 200;
    margin-bottom: 0px;
    border-top: 1px solid rgba(255,255,255,0.9);
}
@media screen and (max-width: 991px){
    .footer-col{
        margin-bottom: 40px;
    }
}