.testimonials{
    background-color: var(--black-100);
}
.testimonials .owl-theme .item{
    margin: 15px;
    padding: 30px;
    box-shadow: var(--shadow-block-100);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    text-align: center;
}
.testimonials .item .img-box{
    height: 100px;
    width: 100px;
    display: inline-block;
    margin-bottom: 30px;
    border: 4px solid var(--main-color);
    border-radius: 50%;
    position: relative;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.testimonials .item .img-box img{
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    width: 100%;
}
.testimonials .item .img-box i{
    position: absolute;
    height: 30px;
    width: 30px;
    background-color: var(--main-color);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    color: #ffffff;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    left: calc(100% - 15px);
    top: calc(50% - 15px);
}
.testimonials .item p{
    font-size: 16px;
    margin: 0 0 20px;
    color: var(--black-400);
    line-height: 26px;
    font-weight: 600;
}
.testimonials .item h3{
    font-size: 18px;
    color: var(--black-900);
    font-weight: 500;
    margin: 0 0 5px;
    text-transform: uppercase;
}
.testimonials .item span{
    display: block;
    font-size: 16px;
    color: var(--black-400);
    font-weight: 300;
}
.testimonials .item .rating{
    margin-top: 10px;
}
.testimonials .item .rating i{
    display: inline-block;
    font-size: 16px;
    color: var(--color-2);
}