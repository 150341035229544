.toogle-theme{
    position: fixed;
    right: 0;
    top: calc(50% - 20px);
    width: 40px;
    height: 40px;
    background-color: var(--dark-to-main-color);
    box-shadow: var(--shadow-black-300);
    z-index: 1200;
    color: #fff;
    font-size: 16px;
    text-align: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    cursor: pointer;
}
.toogle-theme i{
    line-height: 40px;
}