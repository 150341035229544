.pricing-plan{
    box-shadow: var(--shadow-block-100);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.pricing-plan .pricing-header{
    padding: 20px 30px;
    border-bottom: 1px solid var(--black-alpha-100);
}
.pricing-plan .pricing-header h3{
    text-align: center;
    font-size: 30px;
    color: var(--black-900);
    font-weight: 500;
    text-transform: capitalize;
    margin: 0;
    font-family: 'Baloo Da 2', cursive;
    font-family: 'Hind Siliguri', sans-serif;
    font-family: 'Roboto', sans-serif;
}
.pricing-plan .pricing-price{
    padding: 40px 30px;
    display: flex;
    justify-content: center;
    line-height: 0.7;
}
.pricing-plan .pricing-price .currency{
  font-size: 16px;
  font-weight: 300;
  color: var(--black-400); 
  margin-right: 5px;
  align-self: flex-start; 
}
.pricing-plan .pricing-price .price{
    font-size: 80px;
    font-weight: 700;
    color: var(--main-color);
}
.pricing-plan .pricing-price .period{
    font-size: 14px;
  font-weight: 300;
  color: var(--black-400); 
  margin-right: 50px;
  align-self: flex-end;
  text-transform: uppercase;
}
.pricing-plan .pricing-body{
    padding: 0 30px;
}
.pricing-plan .pricing-body ul li{
    font-size: 16px;
    font-weight: 300;
    color: var(--black-400);
    padding: 10px 0 10px 30px;
    border-bottom: 1px solid var(--black-alpha-100);
    line-height: 26px;
    position: relative;
}
.pricing-plan .pricing-body ul li:last-child{
    border-bottom: none;
}
.pricing-plan .pricing-body ul li i{
    position: absolute;
    color: var(--main-color);
    left: 0;
    top: 12px;
}

.pricing-plan .pricing-footer{
    padding: 20px 30px 30px;
    text-align: center;
}
/* Team Section */
.team{
    background-color: var(--black-100);
}
.team .item{
    margin: 15px;
    padding: 30px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: var(--shadow-block-100);
}
.team .item h3{
    font-size: 18px;
    color: var(--black-900);
    font-weight: 500;
    margin: 15px 0 8px;
    text-transform: capitalize;
}
.team .item span{
    display: block;
    font-size: 16px;
    font-weight: 300;
    color: var(--black-400);
    text-transform: capitalize;
}

/* Responsive */
@media screen and (max-width: 991px){
    .pricing-plan{
        margin-bottom: 30px;
    }
}
@media screen and (max-width: 767px){
    
}
@media screen and (max-width: 575px){  
    
}
@media screen and (max-width: 375px) {
    .pricing-plan .pricing-price .price{
        font-size: 65px;
    }
    
}