 .card:not(:last-child){
    margin-bottom: 20px;
}
 .card-header{
    background-color: var(--black-000); 
    box-shadow: var(--shadow-block-100);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    cursor: pointer;
    padding: 2%;
}
.card-header.active{
    background-color: var(--main-color);
}
.card-header i{
    color: var(--black-400);
}
.card-header.active i{
    color: #fff;
}
.card-header h3{
    font-size: 18px;
    font-weight: 600;
    color: var(--black-400);
    margin: 0;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}
.card-header.active h3{
    color: #fff;
    font-size: 12px;
}

.card-body{
    padding: 30px;   
}

.card-body p{
    font-weight: 500;
    font-size: 20px;
    color: var(--black-400);
    line-height: 26px;
    margin: 0;
}

@media screen and (max-width: 575px){
    .card-header h3{
        font-size: 15px;
    }
    .card-header i{
        font-size: 15px;
    }
}