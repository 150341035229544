.screenshots-item{
    /* width: 100%;
    max-width: 250px;
    display: block;
    margin: auto; */
}
/* App Download Section */
.app-download{
    background-image: url('../../Image/mobiile.png');
    background-position: center top;
    background-attachment: fixed;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;    
}
.app-download::before{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--main-color);
    opacity: 0.8;
    z-index: -1;
}
.app-download .section-title h2{
    color: #fff;
}
.app-download-item{
    background-color: var(--black-000);
    padding: 30px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    text-align: center;
    box-shadow: var(--shadow-block-100);
}
.app-download-item i{
    font-size: 30px;
    color: var(--main-color);
    display: inline-block;
}
.app-download-item h3{
    font-size: 22px;
    color: var(--black-900);
    margin: 20px 0;
    font-weight: 500;
    text-transform: capitalize;
}
.app-download-item p{
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    color: var(--black-400);
    margin: 0 0 30px;
}
/* How it works section */
.how-it-works-item{
    box-shadow: var(--shadow-block-100);
    padding: 30px;
    text-align: center;
    position: relative;
    z-index: 1;
}
.how-it-works-item.line-right::before{
    content: '';
    position: absolute;
    top: 60px;
    left: 50%;
    width: 100%;
    height: 2px;
    background-color: var(--main-color);
    z-index: -1;
}
.how-it-works-item .step{
    width: 60px;
    height: 60px;
    background-color: var(--main-color);
    display: inline-block;
    font-size: 30px;
    line-height: 60px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    color: #fff;
    font-weight: 500;
    margin: 0 0 15px;
}
.how-it-works-item h3{
    margin: 20px 0;
    font-size: 22px;
    font-weight: 500;
    color: var(--black-900);
    text-transform: capitalize;
}
.how-it-works-item p{
    font-size: 16px;
    font-weight: 300;
    margin: 0;
    color: var(--black-400);
    line-height: 26px;
}

/* Responsive */
@media screen and (max-width: 991px){
    .how-it-works-item,
   .app-download-item{
       margin-bottom: 30px;
   }
   .how-it-works-item.line-right::before{
       content: none;
   }
   .how-it-works-item{
       box-shadow: var(--shadow-block-100);
       border-radius: 10px;
       -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
       -ms-border-radius: 10px;
       -o-border-radius: 10px;
}
}
@media screen and (max-width: 767px){
    
}
@media screen and (max-width: 575px){  
    
}
@media screen and (max-width: 375px) {
    
    
}