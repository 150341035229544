.features-theme .item{
    box-shadow: var(--shadow-block-100);
    margin: 15px;
    padding: 30px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    text-align: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.features-theme .item::after,
.features-theme .item::before{
    content: '';
    position: absolute;
    z-index: -1;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    opacity: 0;
}
.features-theme .item:hover:before{
    left: 10px;
    opacity: 1;
}
.features-theme .item:hover::after{
    right: 15px;
    opacity: 1;
}
.features-theme .item::before{
    height: 20px;
    width: 20px;
    background-color: var(--color-1);
    left: -20px;
    top: 40%;
}
.features-theme .item::after{
    height: 30px;
    width: 30px;
    background-color: var(--color-2);
    right: -30px;
    top: 80%;
}

.features-theme .item .icon{
    height: 60px;
    width: 60px;
    font-size: 30px;
    margin: 0 auto 30px;
    color: var(--main-color);
    text-align: center;
    position: relative;
    z-index: 1;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}
.features-theme .item .icon::before{
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    background-color: var(--main-color);
    transition: all 0.5s ease;    
    z-index: -1;
    opacity: 0;
}
.features-theme .item:hover .icon::before{
    left: 0;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    opacity: 1;
}
.features-theme .item:hover .icon{
    color: #fff;
}
.features-theme .item i{
    line-height: 60px;
}
.features-theme .item h3{
    font-size: 22px;
    margin: 0 0 20px;
    color: var(--black-900);
    font-weight: 500;
    text-transform: capitalize;
}
.features-theme .item p{
    font-size: 20px;
    line-height: 26px;
    font-weight: 300;
    color: var(--black-900);
    margin: 0 0 15px;
}
.owl-theme .owl-nav{
    margin: 0;
}

/* Responsive */
@media screen and (max-width: 991px){
    
}
@media screen and (max-width: 767px){
    
}
@media screen and (max-width: 575px){  
    
}
@media screen and (max-width: 375px) {
    
    
}