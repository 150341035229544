.contact{
    background-color: var(--black-100);
}
.contact-info h3{
    font-size: 22px;
    color: var(--black-900);
    font-weight: 500;
    margin: 0 0 40px;
}
.contact-info-item{
    position: relative;
    padding-left: 55px;
    margin-bottom: 30px;
}
.contact-info-item i{
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    font-size: 16px;
    color: var(--main-color);
    border: 1px solid var(--main-color);
    text-align: center;
    line-height: 38px;
    left: 0;
    top: 0;
}
.contact-info-item h4{
    font-size: 18px;
    font-weight: 400;
    margin: 0 0 10px;
    color: var(--black-400);
}
.contact-info-item p{
    font-size: 16px;
    font-weight: 300;
    margin: 0;
    line-height: 26px;
    color: var(--black-400);
}

.contact-form .form-group{
    margin-bottom: 25px;
}
.contact-form .form-control{
    height: 52px;
    border: 1px solid transparent;
    box-shadow: var(--shadow-block-100);
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    padding: 0 24px;
    color: var(--black-900);
    background-color: var(--black-000);
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}
.contact-form .form-control:focus{
    border-color: var(--main-color);
}
.contact-form textarea.form-control{
    height: 140px;
    padding-top: 12px;
    resize: none;
}
/* Responsive */
@media screen and (max-width: 991px){
    .contact-form{
        margin-top: 20px;
    }
}