.home{
    min-height: 100vh;
    padding: 150px 0;
    background-color: var(--top-color);
    border-radius: 0 0 200px 0;
    -webkit-border-radius: 0 0 200px 0;
    -moz-border-radius: 0 0 200px 0;
    -ms-border-radius: 0 0 200px 0;
    -o-border-radius: 0 0 200px 0;
    position: relative;
    z-index: 1;
}
.home .home-text h1{
    font-size: 45px;
    font-weight: 700;
    color: #fff;
    margin: 0 0 20px;
}
.home .home-text p{
    font-size: 25px;
    line-height: 28px;
    color: #fff;
}
.home .home-img{
    position: relative;
}
.home .home-img .circle{
    content: '';
    position: absolute;
    z-index: 1;
    width: 400px;
    height: 400px;
    border: 1px solid rgba(255,255,255,0.2);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}
.home .home-img .circle::before{
    position: absolute;
    content: '';
    background-image: url(../../Image/logo2.png);
    height: 30%;
    width: 30%;
    mix-blend-mode: darken;
    /* color: red; */
    /* background: rgb(60, 60, 60); */
    background-color: rgba(29, 18, 18, 0);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    left: 30px;
    top: 30px;
    transform-origin: 170px 170px;
    animation: spin_1 10s linear infinite;
    -webkit-animation: spin_1 10s linear infinite;
}

.home .home-img .circles{
    content: '';
    position: absolute;
    z-index: 1;
    width: 400px;
    height: 400px;
    border: 1px solid rgba(255,255,255,0.2);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}

.home .home-img .circles::before{
    position: absolute;
    content: '';
    background-color: rgba(255,255,255,0.2);
    height: 15%;
    width: 15%;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    left: 30px;
    top: 30px;
    transform-origin: 170px 170px;
    animation: spin_1 10s linear infinite;
    -webkit-animation: spin_1 10s linear infinite;
}
.home .home-img img{
    max-width: 250px;
    width: 100%;
    box-shadow: var(--shadow-black-100);
    border-radius: 32px;
    -webkit-border-radius: 32px;
    -moz-border-radius: 32px;
    -ms-border-radius: 32px;
    -o-border-radius: 32px;
    animation: bounceTop_01 3s ease infinite;
   
}
.home .home-btn{
    margin-top: 40px;
}
.home .home-btn .video-play-btn{
    margin-left: 30px;
    height: 50px;
    width: 50px;
    padding: 0;
    font-size: 16px;
    animation: plus_01 2s ease infinite;
}
.home .home-btn .video-play-btn:hover{
    animation: none;
    -webkit-animation: none;
    box-shadow: var(--shadow-black-300);
}
.home .home-btn .video-play-btn i{
    line-height: 30px;
}
.video-popup{
    padding: 30px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1999;
    background-color: rgba(0,0,0,0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}
.video-popup.open{
    opacity: 1;
    visibility: visible;
}

.video-popup-inner{
    width: 100%;    
    max-width: 900px;
    position: relative;
}
.video-player-close{
    position: absolute;
    right: 0;
    top: -30px;
    height: 30px;
    width: 30px;
    cursor: pointer;
    text-align: center;
    line-height: 30px;
    color: #fff;
}
.iframe-box{
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
}
#player-1{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0px;
}
/* Responsive */
@media screen and (max-width: 991px){
    .home .home-img .circle{
        height: 320px;
        width: 320px;
    }
    .home .home-img .circle::before{
        left: 18px;
        top: 18px;
        transform-origin: 142px 142px;        
    }
    .home .home-text h1{
        font-size: 40px;
    }
}
@media screen and (max-width: 767px){
    .home .home-text{
        margin-bottom: 80px;
    }
}
@media screen and (max-width: 575px){  
    .home{        
     padding: 115px 0;
     overflow-x: hidden;
    } 
    .home .home-text h1{
        font-size: 30px;
    }
    .home .home-text p{
        font-size: 16px;
    }
}
@media screen and (max-width: 375px) {
    .home .home-img img{
        margin: auto;
    } 
    .btn.btn-1{
        padding: 10px 28px;
        font-size: 15px;
    }
}

